html, body {
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 100%;
}

body {
  color: #333;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
  font-family: "Press Start 2P", monospace;
  font-size: 16px;
  line-height: 1.2;
}

p {
  font-family: "Press Start 2P", monospace;
  line-height: 1.2;
}

.footer-text {
  color: #708090;
  font-size: 12px;
  position: absolute;
  bottom: 30px;
}

.footer-link {
  color: #708090;
  font-size: 12px;
  position: absolute;
  bottom: 10px;
}

.text-option-container {
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  display: flex;
}

#menuTextDisplay {
  justify-content: center;
  align-items: center;
  min-height: 0;
  padding: 10px;
  display: none;
}

#menuOptionDisplay {
  display: none;
}

.text-display {
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  min-height: 100px;
  font-family: "Press Start 2P", monospace;
  line-height: 1.2;
  display: flex;
  overflow: hidden auto;
}

#textDisplayWrapper {
  flex: auto;
  justify-content: center;
  align-items: start;
  width: 100%;
  max-width: 800px;
  min-height: 100px;
  max-height: 120px;
  display: flex;
}

#characterDisplay {
  background-repeat: no-repeat;
  outline: 2px solid #333;
  flex: auto;
  width: 100%;
  max-width: 120px;
  height: 100%;
  margin-right: 10px;
}

#characterNameDisplay {
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  text-transform: capitalize;
  background-color: #f5f5f5;
  border: 2px solid #333;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-family: "Press Start 2P", monospace;
  font-weight: bold;
  line-height: 1.2;
  display: flex;
  position: absolute;
  top: -18px;
  left: 18px;
}

#characterImageDisplay {
  -webkit-user-select: none;
  user-select: none;
  width: 120px;
  height: 120px;
}

#textDisplay {
  outline: 2px solid #333;
  flex: auto;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: visible;
}

#textDisplay p {
  margin: 0;
  padding: 20px;
  overflow: auto;
}

#optionDisplayWrapper {
  flex: auto;
  justify-content: center;
  align-items: start;
  width: 100%;
  min-height: 0;
  max-height: 130px;
  display: flex;
}

.option-display {
  outline: 2px solid #333;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  max-height: 130px;
  display: flex;
  overflow: hidden auto;
}

#optionDisplay {
  margin-top: 10px;
}

.option {
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
  width: 100%;
  padding: 8px;
  font-family: "Press Start 2P", monospace;
  line-height: 1.2;
  display: inline-block;
}

.text-with-padding {
  padding: 0 10px;
}

.option:hover {
  color: #f5f5f5;
  cursor: pointer;
  background-color: #333;
}

.option:first-child {
  border-top: none;
}

.option:last-child {
  border-bottom: none;
}

.hud {
  box-sizing: border-box;
  z-index: 3;
  justify-content: space-between;
  align-items: start;
  gap: 10px;
  width: 100%;
  padding: 10px;
  display: flex;
}

.health-container {
  flex-grow: 4;
  align-items: center;
  width: 100%;
  min-width: 250px;
  display: flex;
}

.health-bar {
  background-color: #ccc;
  border: 2px solid #333;
  width: 100%;
  height: 30px;
  overflow: hidden;
}

.health-level {
  background-color: #228b22;
  height: 100%;
  transition: width .5s;
}

.health-and-xp-container {
  flex-direction: column;
  flex-grow: 4;
  align-items: center;
  min-width: 250px;
  max-width: 400px;
  display: none;
}

.experience-container {
  flex-grow: 4;
  align-items: center;
  width: 100%;
  display: flex;
}

.experience-bar {
  background-color: #ccc;
  border: 2px solid #333;
  width: 100%;
  height: 10px;
  overflow: hidden;
}

.experience-level {
  background-color: #007fff;
  width: 0;
  height: 100%;
  transition: width 1s;
}

#enemyHealthLabel {
  height: unset;
  border: 0;
  line-height: 1;
}

.health-label, .magic-label {
  background: #f5f5f5;
  border: 2px solid #333;
  border-right: 0;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
  line-height: 2;
}

.mp-display, .hp-display, .xp-display {
  margin-left: 10px;
  font-weight: bold;
  line-height: 2;
  position: absolute;
}

.magic-container {
  flex-grow: 2;
  align-items: center;
  width: 150px;
  min-width: 150px;
  max-width: 200px;
  display: none;
}

.magic-bar {
  background-color: #ccc;
  border: 2px solid #333;
  width: 100%;
  height: 30px;
  overflow: hidden;
}

.magic-level {
  background-color: #0f52ba;
  width: 0;
  height: 100%;
  transition: width .5s;
}

.inventory-container {
  flex-grow: 1;
  align-items: center;
  display: flex;
}

.inventory-wrapper {
  cursor: pointer;
  background: #f5f5f5;
  border: 2px solid #333;
  align-items: center;
  display: flex;
}

.inventory-icon {
  width: 30px;
}

.game {
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
}

.menu {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
  display: flex;
}

.logo {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: block;
}

.main-display {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  display: flex;
}

.display-div {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.version {
  font-size: 12px;
  position: fixed;
  top: 10px;
  left: 10px;
}

.phoenix-flame-logo {
  width: 100px;
  position: fixed;
  top: 10px;
  right: 10px;
}

.entrance-tile {
  z-index: 3;
  transition: box-shadow .3s ease-in-out;
  box-shadow: 0 0 15px 10px #f0ff9659;
}

.entrance-tile:hover {
  box-shadow: 0 0 25px 15px #f0ff9680;
}

@media only screen and (width <= 580px) {
  body {
    zoom: .8;
  }

  #textDisplayWrapper {
    flex-direction: column;
    flex-grow: 0;
    align-items: end;
    max-height: 280px;
  }

  #characterDisplay {
    height: 120px;
    max-height: 120px;
    margin-bottom: 10px;
    margin-right: 0;
  }

  #textDisplay {
    height: 120px;
    max-height: 120px;
  }

  .hud {
    flex-wrap: wrap;
  }

  #healthAndXpContainer {
    min-width: 100%;
  }

  #inventoryContainer {
    order: 2;
  }

  #magicContainer {
    order: 3;
  }
}

@media only screen and (height <= 720px) {
  .text-option-container {
    zoom: .9;
  }
}

@media only screen and (height <= 690px) {
  .main-display {
    zoom: .9;
  }
}

@media only screen and (height <= 650px) {
  .text-option-container {
    zoom: .8;
  }
}

@media only screen and (height <= 620px) {
  .main-display {
    zoom: .8;
  }
}
/*# sourceMappingURL=index.b6d941ad.css.map */
