/* 
Color Palette:

Background Colors:
Off-White (#F5F5F5): A light, neutral color that provides a clean and readable backdrop for text.

Text Colors:
Dark Charcoal (#333333): For primary text, ensuring high contrast and readability against the off-white background.
Slate Gray (#708090): For secondary text, such as descriptions or additional information.

Accent Colors:
Crimson (#DC143C) for health bars or warnings.
Forest Green (#228B22) for success messages or health restoration indicators.
Sapphire Blue (#0F52BA) for mana or magic-related elements.
Azure Blue (#007FFF) for experience bars or level indicators.
Gold (#FFD700) for highlighting important text, like item names or critical actions.

*/

html,
body {
  height: 100%;
  user-select: none;
  /* Prevent double-tap zoom on iOS */
  touch-action: manipulation;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Press Start 2P', monospace;
  line-height: 1.2;
  background-color: #f5f5f5;
  color: #333333;
  font-size: 16px;
}

p {
  font-family: 'Press Start 2P', monospace;
  line-height: 1.2;
}

.footer-text {
  font-size: 12px;
  color: #708090;
  position: absolute;
  bottom: 30;
}

.footer-link {
  font-size: 12px;
  color: #708090;
  position: absolute;
  bottom: 10;
}

.text-option-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px;
  flex-grow: 1;
}

#menuTextDisplay {
  min-height: 0px;
  display: none;
  padding: 10px;
  justify-content: center;
  align-items: center;
}

#menuOptionDisplay {
  display: none;
}

.text-display {
  min-height: 100px;
  width: 100%;
  font-family: 'Press Start 2P', monospace;
  line-height: 1.2;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
}

#textDisplayWrapper {
  width: 100%;
  max-width: 800px;
  max-height: 120px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex: 1 1 auto;
}

/* The character display is a flex container that will hold the character's name and portrait */
#characterDisplay {
  outline: 2px solid #333;
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  margin-right: 10px;
  background-repeat: no-repeat;
  max-width: 120px;
}

#characterNameDisplay {
  background-color: #f5f5f5;
  border: 2px solid #333;
  padding: 5px;
  text-align: center;
  font-weight: bold;
  font-family: 'Press Start 2P', monospace;
  line-height: 1.2;
  user-select: none;
  position: absolute;
  top: -18px;
  left: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
}

#characterImageDisplay {
  width: 120px;
  height: 120px;
  user-select: none;
}

#textDisplay {
  outline: 2px solid #333;
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  position: relative;
  overflow: visible;
}

#textDisplay p {
  padding: 20px;
  overflow: auto;
  margin: 0;
}

#optionDisplayWrapper {
  width: 100%;
  max-height: 130px;
  min-height: 0px;
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: start;
}

.option-display {
  width: 100%;
  max-width: 800px;
  max-height: 130px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  outline: 2px solid #333;
}

#optionDisplay {
  margin-top: 10px;
}

.option {
  width: 100%;
  display: inline-block;
  font-family: 'Press Start 2P', monospace;
  line-height: 1.2;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
  user-select: none;
}

.text-with-padding {
  padding: 0 10px;
}

.option:hover {
  background-color: #333;
  color: #f5f5f5;
  cursor: pointer;
}

.option:first-child {
  border-top: none;
}

.option:last-child {
  border-bottom: none;
}

.hud {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 10px;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
  z-index: 3;
}

.health-container {
  min-width: 250px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-grow: 4;
}

.health-bar {
  width: 100%;
  height: 30px;
  background-color: #ccc;
  border: 2px solid #333;
  overflow: hidden;
}

.health-level {
  height: 100%;
  background-color: #228b22;
  transition: width 0.5s ease; /* Smooth animation for health changes */
}

.health-and-xp-container {
  min-width: 250px;
  display: none; /* Updated to flex during tutorial */
  flex-direction: column;
  align-items: center;
  flex-grow: 4;
  max-width: 400px;
}

.experience-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-grow: 4;
}

.experience-bar {
  width: 100%;
  height: 10px;
  background-color: #ccc;
  border: 2px solid #333;
  overflow: hidden;
}

.experience-level {
  height: 100%;
  background-color: #007fff;
  transition: width 1s ease; /* Smooth animation for experience changes */
  width: 0;
}

#enemyHealthLabel {
  border: 0;
  line-height: 1;
  height: unset;
}

.health-label,
.magic-label {
  font-weight: bold;
  background: #f5f5f5;
  padding-right: 10px;
  padding-left: 10px;
  height: 30px;
  border: 2px solid #333;
  border-right: 0;
  line-height: 2;
}

.mp-display,
.hp-display {
  font-weight: bold;
  position: absolute;
  margin-left: 10px;
  line-height: 2;
}

.xp-display {
  font-weight: bold;
  position: absolute;
  margin-left: 10px;
  line-height: 2;
}

.magic-container {
  display: none; /* Updated to flex during tutorial */
  align-items: center;
  width: 150px;
  flex-grow: 2;
  max-width: 200px;
  min-width: 150px;
}

.magic-bar {
  width: 100%;
  height: 30px;
  background-color: #ccc;
  border: 2px solid #333;
  overflow: hidden;
}

.magic-level {
  height: 100%;
  background-color: #0f52ba;
  transition: width 0.5s ease; /* Smooth animation for magic changes */
  width: 0;
}

.inventory-container {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.inventory-wrapper {
  display: flex;
  border: 2px solid #333;
  align-items: center;
  background: #f5f5f5;
  cursor: pointer;
}

.inventory-icon {
  width: 30px;
}

.game {
  display: none; /* Hidden when main menu is shown */
  width: 100%;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
  text-align: center;
}

.logo {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
}

.main-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.display-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.version {
  position: fixed;
  top: 10px;
  left: 10px;
  font-size: 12px;
}

.phoenix-flame-logo {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 100px;
}

.entrance-tile {
  box-shadow: 0 0 15px 10px rgba(240, 255, 150, 0.35); /* Glow effect */
  transition: box-shadow 0.3s ease-in-out;
  z-index: 3;
}

.entrance-tile:hover {
  box-shadow: 0 0 25px 15px rgba(240, 255, 150, 0.5);
}

/* Once you hit 580px, the game will perform better in vertical mode */
@media only screen and (max-width: 580px) {
  body {
    zoom: 0.8;
  }
  #textDisplayWrapper {
    align-items: end;
    max-height: 280px;
    flex-direction: column;
    flex-grow: 0;
  }
  #characterDisplay {
    height: 120px;
    max-height: 120px;
    margin-bottom: 10px;
    margin-right: 0;
  }
  #textDisplay {
    height: 120px;
    max-height: 120px;
  }
  .hud {
    flex-wrap: wrap;
  }
  #healthAndXpContainer {
    min-width: 100%;
  }
  #inventoryContainer {
    order: 2;
  }
  #magicContainer {
    order: 3;
  }
}

/* Shrink the text/option display and map display alternatively as the screen height decreases */
@media only screen and (max-height: 720px) {
  .text-option-container {
    zoom: 0.9;
  }
}

@media only screen and (max-height: 690px) {
  .main-display {
    zoom: 0.9;
  }
}

@media only screen and (max-height: 650px) {
  .text-option-container {
    zoom: 0.8;
  }
}

@media only screen and (max-height: 620px) {
  .main-display {
    zoom: 0.8;
  }
}
